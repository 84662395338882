import React from 'react'

const Footer: React.FC = () => {
  return (
    <>
      <div className="menu">
        <p className="company-name">テナサポ不動産株式会社</p>
        <p>東京都港区南麻布2丁目2番28号</p>
      </div>
      <img src="/images/top-jump-button.svg" />
    </>
  )
}

export default Footer
